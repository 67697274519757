import React from 'react'

import './biblioteca.css'
import '../propuestas/propuestas.css'

function Biblioteca() {
  return (
    <main className='compMain'>
      <section className='compMainSection'>
        <div className='compTitleCont'>
          <div className='compTitleLine'/>
          <h1 className='compTitle'>Biblioteca</h1>
          <div className='compTitleLine'/>
        </div>
        <div className='compSubtitleCont'>
          <h3 className='compSubtitle' style={{textAlign:'left', width:"100%", maxWidth:"100%", marginTop:"50px"}}>Proyecto "Ciudadanía, democracia y gestión descentralizada"</h3>
        </div>
        <div className='bibCont' style={{marginTop:"30px"}}>
          <li style={{marginBottom:"10px"}}>
            <a className='propuestasListTitle propuestasListEmail' style={{color:"#666666"}} href='https://institutobicentenario.org/documents/Gabinete_y_sintesis_Arequipa.pdf' target='_blank' rel='noreferrer noopener'>Departamento de Arequipa</a>
          </li>
          <li style={{marginBottom:"10px"}}>
            <a className='propuestasListTitle propuestasListEmail' style={{color:"#666666"}} href='https://institutobicentenario.org/documents/Gabinete_y_sintesis_Cajamarca.pdf' target='_blank' rel='noreferrer noopener'>Departamento de Cajamarca</a>
          </li>
          <li style={{marginBottom:"10px"}}>
            <a className='propuestasListTitle propuestasListEmail' style={{color:"#666666"}} href='https://institutobicentenario.org/documents/Gabinete_y_sintesis_Cusco.pdf' target='_blank' rel='noreferrer noopener'>Departamento de Cusco</a>
          </li>
          <li style={{marginBottom:"10px"}}>
            <a className='propuestasListTitle propuestasListEmail' style={{color:"#666666"}} href='https://institutobicentenario.org/documents/Gabinete_y_sintesis_Junin.pdf' target='_blank' rel='noreferrer noopener'>Departamento de Junín</a>
          </li>
          <li style={{marginBottom:"10px"}}>
            <a className='propuestasListTitle propuestasListEmail' style={{color:"#666666"}} href='https://institutobicentenario.org/documents/Gabinete_y_sintesis_Loreto.pdf' target='_blank' rel='noreferrer noopener'>Departamento de Loreto</a>
          </li>
          <li style={{marginBottom:"10px"}}>
            <a className='propuestasListTitle propuestasListEmail' style={{color:"#666666"}} href='https://institutobicentenario.org/documents/Gabinete_y_sintesis_Piura.pdf' target='_blank' rel='noreferrer noopener'>Departamento de Piura</a>
          </li>
        </div>
        <div className='compTitleBottom'>Biblioteca</div>
      </section>
    </main>
  )
}

export default Biblioteca