import React from 'react'

import './propuestasValor.css'

import propuestaValor1 from '../../media/images/propuestaValor1.jpg'
import propuestaValor2 from '../../media/images/propuestaValor2.jpg'
import propuestaValor3 from '../../media/images/propuestaValor3.jpg'
import propuestaValor4 from '../../media/images/propuestaValor4.jpg'

function PropuestasValor() {
  return (
    <main className='compMain'>
      <section className='compMainSection'>
        <div className='compTitleCont'>
          <div className='compTitleLine'/>
          <h1 className='compTitle'>Propuesta de valor</h1>
          <div className='compTitleLine'/>
        </div>
        <div className='valuesCont'>
          <div className='valueCont'>
            <img className='valueContImg' src={propuestaValor1} alt="Ayudar" />
            <div className='valueContTextCont'>
              <p className='valueContText'><b>Ayudar</b> a que organizaciones de la sociedad civil, sin filiación partidaria ni intereses políticos, se involucren activamente en la vigilancia del Estado.</p>
            </div>
          </div>
          <div className='valueCont'>
            <img className='valueContImg' src={propuestaValor2} alt="Lograr" />
            <div className='valueContTextCont'>
              <p className='valueContText'><b>Lograr</b> que actores públicos y privados actúen de manera honesta, transparente y eficiente, rindiendo cuentas a la población.</p>
            </div>
          </div>
          <div className='valueCont'>
            <img className='valueContImg' src={propuestaValor3} alt="Promover" />
            <div className='valueContTextCont'>
              <p className='valueContText'><b>Promover</b> la defensa de la democracia, los derechos humanos y las libertades, combatiendo todo tipo de discriminación.</p>
            </div>
          </div>
          <div className='valueCont'>
            <img className='valueContImg' src={propuestaValor4} alt="Fomentar" />
            <div className='valueContTextCont'>
              <p className='valueContText'><b>Fomentar</b> la defensa de la propiedad privada y la libertad empresarial.</p>
            </div>
          </div>
        </div>
        <div className='compTitleBottom'>Propuesta de valor</div>
      </section>
    </main>
  )
}

export default PropuestasValor