import React, {useState} from 'react'

import './navbar.css'

function Navbar() {

    const [openNav, setOpenNav] = useState(false)
    return (
        <nav className='navbar'>
            <a className='navbarLogo' href='/' rel='nofererrer'>
                <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 403.49 120">
                    <g>
                        <path fill="#fff" strokeWidth="0px" d="M26.99,12.36c0,26.31,21.33,47.64,47.64,47.64V12.36H26.99Z"/>
                        <path fill="#fc0000" strokeWidth="0px" d="M74.64,107.64c-26.31,0-47.64-21.33-47.64-47.64h47.64v47.64Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M122.28,107.64c-26.31,0-47.64-21.33-47.64-47.64h47.64v47.64Z"/>
                        <path fill="#fc0000" strokeWidth="0px" d="M122.28,60c-26.31,0-47.64-21.33-47.64-47.64h47.64v47.64Z"/>
                    </g>
                    <g>
                        <path fill="#fff" strokeWidth="0px" d="M141.14,38.32h6.6v27.08h-6.6v-27.08Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M167.36,53.71c0-2.93-1.62-4.55-4.13-4.55s-4.13,1.62-4.13,4.55v11.69h-6.59v-21.52h6.59v2.85c1.31-1.81,3.63-3.09,6.52-3.09,4.98,0,8.29,3.39,8.29,9.18v12.57h-6.56v-11.69Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M187.26,65.71c-5.75,0-9.6-3.2-9.91-7.37h6.52c.15,1.5,1.5,2.51,3.32,2.51,1.7,0,2.58-.77,2.58-1.74,0-3.47-11.73-.96-11.73-8.87,0-3.66,3.13-6.67,8.76-6.67s8.64,3.09,9.06,7.33h-6.09c-.19-1.47-1.31-2.43-3.16-2.43-1.54,0-2.39.62-2.39,1.66,0,3.43,11.65,1,11.76,9.03,0,3.74-3.32,6.56-8.72,6.56Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M201.07,49.35h-2.62v-5.48h2.62v-5.25h6.59v5.25h4.32v5.48h-4.32v8.64c0,1.27.54,1.81,2.01,1.81h2.35v5.59h-3.36c-4.47,0-7.6-1.89-7.6-7.48v-8.56Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M215.06,38.09c0-2.01,1.58-3.59,3.93-3.59s3.9,1.58,3.9,3.59-1.58,3.55-3.9,3.55-3.93-1.58-3.93-3.55ZM215.68,43.88h6.6v21.52h-6.6v-21.52Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M228.1,49.35h-2.62v-5.48h2.62v-5.25h6.59v5.25h4.32v5.48h-4.32v8.64c0,1.27.54,1.81,2.01,1.81h2.35v5.59h-3.36c-4.47,0-7.6-1.89-7.6-7.48v-8.56Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M263.97,65.4h-6.59v-2.93c-1.31,1.85-3.63,3.16-6.52,3.16-4.98,0-8.33-3.43-8.33-9.18v-12.57h6.56v11.69c0,2.93,1.66,4.55,4.13,4.55s4.17-1.62,4.17-4.55v-11.69h6.59v21.52Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M269.75,49.35h-2.62v-5.48h2.62v-5.25h6.59v5.25h4.32v5.48h-4.32v8.64c0,1.27.54,1.81,2.01,1.81h2.35v5.59h-3.36c-4.47,0-7.6-1.89-7.6-7.48v-8.56Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M294.16,65.71c-6.33,0-11.11-4.24-11.11-11.07s4.9-11.07,11.19-11.07,11.19,4.24,11.19,11.07-4.94,11.07-11.26,11.07ZM294.16,60c2.35,0,4.55-1.74,4.55-5.36s-2.16-5.36-4.47-5.36-4.47,1.7-4.47,5.36,2.01,5.36,4.4,5.36Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M151.16,105.72h-9.33v-26.96h9.06c5.67,0,8.37,3.01,8.37,6.79,0,3.55-2.24,5.52-4.82,6.25,3.01.54,5.44,3.32,5.44,6.71,0,4.05-3.05,7.21-8.72,7.21ZM150.7,81.03h-6.17v9.64h6.29c3.66,0,5.71-1.85,5.71-4.86s-1.93-4.78-5.82-4.78ZM150.86,92.91h-6.33v10.53h6.44c3.9,0,6.21-1.97,6.21-5.21s-2.47-5.32-6.33-5.32Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M164.24,78.68c0-1.16.89-1.97,1.97-1.97s1.97.81,1.97,1.97-.89,2.01-1.97,2.01-1.97-.85-1.97-2.01ZM164.85,84.66h2.7v21.06h-2.7v-21.06Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M182.67,84.35c5.28,0,8.72,2.82,9.68,7.17h-2.89c-.69-3.01-3.24-4.86-6.79-4.86-4.13,0-7.48,2.82-7.48,8.52s3.36,8.56,7.48,8.56c3.55,0,6.06-1.81,6.79-4.9h2.89c-.96,4.2-4.4,7.17-9.68,7.17-5.94,0-10.22-4.17-10.22-10.84s4.28-10.84,10.22-10.84Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M206.43,106.03c-5.98,0-10.34-4.17-10.34-10.84s4.28-10.84,10.34-10.84,9.95,4.4,9.95,9.76c0,.85,0,1.39-.08,2.12h-17.47c.23,4.94,3.66,7.52,7.6,7.52,3.59,0,6.02-1.89,6.79-4.71h2.85c-.96,3.97-4.36,6.98-9.64,6.98ZM198.83,94.03h14.81c.12-4.9-3.43-7.37-7.33-7.37s-7.17,2.47-7.48,7.37Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M237.09,93.68c0-4.67-2.55-7.06-6.4-7.06s-6.67,2.47-6.67,7.6v11.49h-2.7v-21.06h2.7v3.59c1.35-2.62,4.05-3.97,7.17-3.97,4.78,0,8.56,2.93,8.56,9.1v12.34h-2.66v-12.03Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M246.49,86.93h-2.93v-2.28h2.93v-5.28h2.74v5.28h5.79v2.28h-5.79v13.08c0,2.58.89,3.39,3.32,3.39h2.47v2.31h-2.89c-3.66,0-5.63-1.5-5.63-5.71v-13.08Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M268.55,106.03c-5.98,0-10.34-4.17-10.34-10.84s4.28-10.84,10.34-10.84,9.95,4.4,9.95,9.76c0,.85,0,1.39-.08,2.12h-17.47c.23,4.94,3.66,7.52,7.6,7.52,3.59,0,6.02-1.89,6.79-4.71h2.85c-.96,3.97-4.36,6.98-9.64,6.98ZM260.95,94.03h14.81c.12-4.9-3.43-7.37-7.33-7.37s-7.17,2.47-7.48,7.37Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M299.21,93.68c0-4.67-2.55-7.06-6.4-7.06s-6.67,2.47-6.67,7.6v11.49h-2.7v-21.06h2.7v3.59c1.35-2.62,4.05-3.97,7.17-3.97,4.78,0,8.56,2.93,8.56,9.1v12.34h-2.66v-12.03Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M316.68,84.35c4.24,0,7.14,2.28,8.33,4.94v-4.63h2.7v21.06h-2.7v-4.67c-1.23,2.7-4.17,4.98-8.37,4.98-5.75,0-10.03-4.28-10.03-10.88s4.28-10.8,10.07-10.8ZM317.18,86.7c-4.44,0-7.83,3.09-7.83,8.45s3.39,8.52,7.83,8.52,7.83-3.24,7.83-8.49-3.51-8.49-7.83-8.49Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M336.58,105.72h-2.7v-21.06h2.7v3.74c1.16-2.58,3.59-4.13,7.21-4.13v2.82h-.73c-3.51,0-6.48,1.58-6.48,6.87v11.76Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M347.22,78.68c0-1.16.89-1.97,1.97-1.97s1.97.81,1.97,1.97-.89,2.01-1.97,2.01-1.97-.85-1.97-2.01ZM347.84,84.66h2.7v21.06h-2.7v-21.06Z"/>
                        <path fill="#fff" strokeWidth="0px" d="M365.89,106.03c-5.94,0-10.45-4.17-10.45-10.84s4.63-10.84,10.53-10.84,10.53,4.13,10.53,10.84-4.67,10.84-10.61,10.84ZM365.89,103.67c3.97,0,7.83-2.7,7.83-8.49s-3.78-8.49-7.79-8.49-7.75,2.7-7.75,8.49,3.7,8.49,7.71,8.49Z"/>
                    </g>
                </svg>
            </a>
            <div className='navBtnCont'>
                <input className='navCheck' type='checkbox' value={openNav} onChange={()=>setOpenNav(!openNav)}></input>
                <span></span>
                <span></span>
                <span></span>
            </div>
            {openNav &&
                <section className='navbarListCont'>
                    <ul className='navbarList'>
                        <a href="/" className='navbarLogoCont'>
                            <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                            </svg>
                        </a>
                        <a href="/propuesta-de-valor" className='navbarLink' style={{borderBottom:"1px solid red"}}>Propuesta de valor</a>
                        <a href="/lineas-de-accion" className='navbarLink' style={{borderBottom:"1px solid red"}}>Líneas de acción</a>
                        <a href="/teoria-de-cambio" className='navbarLink' style={{borderBottom:"1px solid red"}}>Teoría de cambio</a>
                        <a href="/entidades" className='navbarLink' style={{borderBottom:"1px solid red"}}>¿A quiénes hemos apoyado?</a>
                        <a href="/equipo" className='navbarLink' style={{borderBottom:"1px solid red"}}>Equipo</a>
                        <a href="/presentacion-propuestas" className='navbarLink' style={{borderBottom:"1px solid red"}}>¿Cómo presentar una propuesta?</a>
                        <a href="/biblioteca" className='navbarLink' style={{paddingBottom:"0"}}>Biblioteca</a>
                    </ul>
                </section>
            }
        </nav>
    )
}

export default Navbar