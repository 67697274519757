import React, { useState } from 'react'

import './lineasAccion.css'
import '../components.css'

import actionLinesBubA from '../../media/images/lineaDeAccionBubA.png'
import actionLinesBubB from '../../media/images/actionLinesBubB.jpg'
import actionLinesBannerA from '../../media/images/actionLinesBannerA.jpg'
import actionLinesBannerB from '../../media/images/actionLinesBannerB.jpg'

function LineasAccion() {
    const [actionLineMobA, setActionLineMobA] = useState(false)
    const [actionLineMobB, setActionLineMobB] = useState(false)
    const [actionLineWebA, setActionLineWebA] = useState(false)
    const [actionLineWebB, setActionLineWebB] = useState(false)

    return (
        <main className='compMain'>
            <section className='compMainSection'>
                <div className='compTitleCont'>
                    <div className='compTitleLine'/>
                        <h1 className='compTitle'>Nuestras líneas<span className='compTitleWeb'> de acción</span></h1>
                    <div className='compTitleLine'/>
                </div>
                <div className='compTitleCont'>
                    <h1 className='compTitle compTitleMob'>de acción</h1>
                </div>
                <div className='compSubtitleCont'>
                    <h3 className='compSubtitle'>Hasta el 2028 centraremos nuestro trabajo en dos líneas de acción:</h3>
                </div>
            </section>

            <section className='actionLinesBannerCont'>
                <img src={actionLinesBannerA} className='actionLinesBanner' alt='Ciudadanía activa, responsable y vigilante'/>
            </section>
                
            <section className='compMainSection'>
                <div className='actionLinesTop'>
                    <h4 className='actionLinesTitle'>Ciudadanía activa, responsable y vigilante</h4>
                    <div className='actionLinesTextCont'>
                        <li className='actionLinesText'>Apoyamos el fortalecimiento de proyectos que a nivel local busquen mejorar la gobernabilidad y la democracia.</li>
                        {actionLineMobA && <li className='actionLinesText actionLinesMob'>{`Durante los últimos años, la situación política peruana ha llegado a niveles de grave deterioro institucional que afectan el desempeño económico y social del país, lo que ha generado percepciones negativas sobre la democracia. Según el Barómetro de las Américas (2023), solo el 51% de peruanos cree que la democracia es la forma de gobierno preferible para el Perú. Además, apenas 19% señala estar satisfecho con el estado actual de la democracia en el país (en el 2012, en cambio, esa cifra alcanzaba al 52% de la población). Solo 22% confía en las elecciones (en comparación con 38% en la región como conjunto).`}</li>}
                        {actionLineMobA && <li className='actionLinesText actionLinesMob'>{`Sumado a lo anterior, el Índice de Democracia de la Unidad de Inteligencia de The Economist nos ubica por debajo de la media regional en participación política y, desde el 2023, nos considera un régimen híbrido, por debajo del nivel de democracias defectuosas. El problema, sin embargo, es también de desinterés. Una encuesta del Instituto Bicentenario realizada en el 2023 señala que el 72% de la población en el país tiene poco o nada de interés en la política.`}</li>}
                        {actionLineMobA && <li className='actionLinesText actionLinesMob'>{`El Instituto Bicentenario busca mejorar este tipo de indicadores apoyando iniciativas que permitan consolidar una ciudadanía activa, responsable y vigilante. Ello a través de proyectos educativos que fortalezcan la democracia, la institucionalidad, el diálogo ciudadano y la libertad de expresión. El Instituto Bicentenario apoya el fortalecimiento de las entidades y el empoderamiento de los ciudadanos que incidan en mejorar la gobernabilidad y la efectividad de la democracia en sus entornos. Con el compromiso de priorizar iniciativas ciudadanas que involucren a jóvenes y mujeres.`}</li>}
                        <button className='actionLinesBtn actionLinesBtnWeb' onClick={()=>{setActionLineWebA(true)}}>Ver más</button>
                        <button className='actionLinesBtn actionLinesBtnMob' onClick={()=>{setActionLineMobA(!actionLineMobA)}}>{`Ver ${actionLineMobA?'menos':'más'}`}</button>
                        <p className='actionLinesNumber'>1</p>
                    </div>
                    <img className='actionLinesImg' src={actionLinesBubA} alt='Ciudadanía activa, responsable y vigilante'/>
                </div>
            </section>

            <section className='actionLinesBannerCont'>
                <img src={actionLinesBannerB} className='actionLinesBanner' alt='Instituciones descentralizadas efectivas'/>
            </section>

            <section className='compMainSection'>
                <div className='actionLinesBottom'>
                    <h4 className='actionLinesTitleBottom'>Instituciones descentralizadas efectivas</h4>
                    <div className='actionLinesTextCont'>
                        <li className='actionLinesTextBottom'>Patrocinamos iniciativas que robustecen el debate descentralizado, establecen redes, mejoran la efectividad social y económica de las inversiones y programas públicos, favorecen la producción y diseminación de conocimiento, impulsan el análisis social y económico, fomentan la transparencia y eficiencia de las instituciones e incrementan las capacidades de gestión pública a nivel local y regional.</li>
                        {actionLineMobB && <li className='actionLinesTextBottom actionLinesMob'>{`La centralización económica y política en el Perú trae consigo falencias que perpetúan brechas sociales, generan desconfianza en sus instituciones y fomentan mecanismos de corrupción. Para muestra, según una encuesta del Instituto Bicentenario realizada en el 2023, apenas el 21% de peruanos confía mucho o algo en el buen manejo de fondos por parte de municipalidades.`}</li>}
                        {actionLineMobB && <li className='actionLinesTextBottom actionLinesMob'>{`Sumado a ello, la Encuesta Nacional de Percepciones de la Corrupción en el Perú 2022 de ProÉtica muestra que el 81% de la población considera que la sensación de corrupción empeoró en los cinco años previos al sondeo. En la misma línea, el 87% de los encuestados por el Barómetro de las Américas en el 2023 considera que más de la mitad de los políticos son corruptos, muy por encima del 62% que opina lo mismo en la región como conjunto.`}</li>}
                        {actionLineMobB && <li className='actionLinesTextBottom actionLinesMob'>{`Para atender esta situación desde la sociedad civil se requiere fomentar la fiscalización ciudadana, reducir el déficit en acceso a la información y apoyar la búsqueda de un Estado eficiente y transparente en todos los niveles de gobierno. Por ello, el Instituto Bicentenario apoya iniciativas que fortalecen el debate descentralizado, establecen redes, mejoran la efectividad social y económica de las inversiones y programas públicos o favorecen la producción y la transferencia del conocimiento. También aquellas que impulsan el análisis social y económico, fomentan la transparencia y eficiencia de las instituciones públicas e incrementan las capacidades de gestión pública a nivel local y regional.`}</li>}
                        <button className='actionLinesBtnBottom actionLinesBtnWeb' onClick={()=>{setActionLineWebB(true)}}>Ver más</button>
                        <button className='actionLinesBtnBottom actionLinesBtnMob' onClick={()=>{setActionLineMobB(!actionLineMobB)}}>{`Ver ${actionLineMobB?'menos':'más'}`}</button>
                        <p className='actionLinesNumberBottom'>2</p>
                    </div>
                    <img className='actionLinesImgBottom' src={actionLinesBubB} alt='Instituciones descentralizadas efectivas'/>
                </div>

                <div className='actionLinesMoreBtnCont actionLinesWeb'>
                    <a className='actionLinesMoreBtn' href='/teoria-de-cambio' rel='noreferrer noopener'>Haz clic aquí para conocer nuestra teoría de cambio</a>
                </div>

                <div className='compTitleBottom'>Nuestras líneas de acción</div>
            </section>


            {actionLineWebA && 
                <div className='compModalCont actionLinesWeb'>
                    <div className='actionLinesModal'>
                        <button
                            className='compModalCloseBtn'
                            onClick={()=>{
                                setActionLineWebA(false)
                            }}
                        >
                            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.0359 7.67847L7.67871 23.0356" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.67871 7.67847L23.0359 23.0356" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <p className='actionLinesModalTitle'>Ciudadanía activa, responsable y vigilante</p>
                        <li className='actionLinesModalText'>{`Durante los últimos años, la situación política peruana ha llegado a niveles de grave deterioro institucional que afectan el desempeño económico y social del país, lo que ha generado percepciones negativas sobre la democracia. Según el Barómetro de las Américas (2023), solo el 51% de peruanos cree que la democracia es la forma de gobierno preferible para el Perú. Además, apenas 19% señala estar satisfecho con el estado actual de la democracia en el país (en el 2012, en cambio, esa cifra alcanzaba al 52% de la población). Solo 22% confía en las elecciones (en comparación con 38% en la región como conjunto).`}</li>
                        <li className='actionLinesModalText'>{`Sumado a lo anterior, el Índice de Democracia de la Unidad de Inteligencia de The Economist nos ubica por debajo de la media regional en participación política y, desde el 2023, nos considera un régimen híbrido, por debajo del nivel de democracias defectuosas. El problema, sin embargo, es también de desinterés. Una encuesta del Instituto Bicentenario realizada en el 2023 señala que el 72% de la población en el país tiene poco o nada de interés en la política.`}</li>
                        <li className='actionLinesModalText'>{`El Instituto Bicentenario busca mejorar este tipo de indicadores apoyando iniciativas que permitan consolidar una ciudadanía activa, responsable y vigilante. Ello a través de proyectos educativos que fortalezcan la democracia, la institucionalidad, el diálogo ciudadano y la libertad de expresión. El Instituto Bicentenario apoya el fortalecimiento de las entidades y el empoderamiento de los ciudadanos que incidan en mejorar la gobernabilidad y la efectividad de la democracia en sus entornos. Con el compromiso de priorizar iniciativas ciudadanas que involucren a jóvenes y mujeres.`}</li>
                    </div>
                </div>
            }

            {actionLineWebB && 
                <div className='compModalCont actionLinesWeb'>
                    <div className='actionLinesModal'>
                        <button
                            className='compModalCloseBtn'
                            onClick={()=>{
                                setActionLineWebB(false)
                            }}
                        >
                            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.0359 7.67847L7.67871 23.0356" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.67871 7.67847L23.0359 23.0356" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <p className='actionLinesModalTitle'>Instituciones descentralizadas efectivas</p>
                        <li className='actionLinesModalText'>{`La centralización económica y política en el Perú trae consigo falencias que perpetúan brechas sociales, generan desconfianza en sus instituciones y fomentan mecanismos de corrupción. Para muestra, según una encuesta del Instituto Bicentenario realizada en el 2023, apenas el 21% de peruanos confía mucho o algo en el buen manejo de fondos por parte de municipalidades.`}</li>
                        <li className='actionLinesModalText'>{`Sumado a ello, la Encuesta Nacional de Percepciones de la Corrupción en el Perú 2022 de ProÉtica muestra que el 81% de la población considera que la sensación de corrupción empeoró en los cinco años previos al sondeo. En la misma línea, el 87% de los encuestados por el Barómetro de las Américas en el 2023 considera que más de la mitad de los políticos son corruptos, muy por encima del 62% que opina lo mismo en la región como conjunto.`}</li>
                        <li className='actionLinesModalText'>{`Para atender esta situación desde la sociedad civil se requiere fomentar la fiscalización ciudadana, reducir el déficit en acceso a la información y apoyar la búsqueda de un Estado eficiente y transparente en todos los niveles de gobierno. Por ello, el Instituto Bicentenario apoya iniciativas que fortalecen el debate descentralizado, establecen redes, mejoran la efectividad social y económica de las inversiones y programas públicos o favorecen la producción y la transferencia del conocimiento. También aquellas que impulsan el análisis social y económico, fomentan la transparencia y eficiencia de las instituciones públicas e incrementan las capacidades de gestión pública a nivel local y regional.`}</li>
                    </div>
                </div>
            }

        </main>
    )
}

export default LineasAccion