import React, { useState } from 'react'

import homeBrands from '../home/homeBrands.json'

function Entidades() {
  const [openBrand, setOpenBrand] = useState(false)
  const [brandModal, setBrandModal] = useState({})
  const [modalBrandPart, setModalBrandPart] = useState(true)

  return (
    <main className='compMain'>
      <section className='compMainSection'>
        <div className='compTitleCont'>
          <div className='compTitleLine'/>
          <h1 className='compTitle'>¿A quiénes hemos<span className='compTitleWeb'> apoyado?</span></h1>
          <div className='compTitleLine'/>
        </div>
        <div className='compTitleCont'>
          <h1 className='compTitle compTitleMob'>apoyado?</h1>
        </div>

        <div className='compSubtitleCont'>
          <h3 className='compSubtitle'>Buscamos el cumplimiento de nuestras líneas de acción a través del desarrollo de proyectos en Piura, Cajamarca, Loreto, Arequipa, Junín y Cusco</h3>
        </div>

        <div className='compSubtitleCont'>
          <h3 className='compSubtitle'>Haz clic en cada una de las organizaciones para saber más</h3>
        </div>

        <div className='homeBrandsCont'>
          {homeBrands.map((brand)=>{
            return(
              <button
                className='homeBrandBtn'
                onClick={()=>{
                  setBrandModal(brand)
                  setOpenBrand(true)
                }}
              >
                <img className='homeBrandImg' src={brand.logo}/>
              </button>
            )
          })}
        </div>
        <div className='compTitleBottom'>Iniciativas</div>
      </section>

      {openBrand &&
        <div className='compModalCont'>
          <div className='homeBrandModal'>
            <button
              className='compModalCloseBtn'
              onClick={()=>{
                setOpenBrand(false)
                setBrandModal({})
              }}
            >
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.0359 7.67847L7.67871 23.0356" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.67871 7.67847L23.0359 23.0356" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <img className='homeBrandModalImg' src={brandModal.logoAlt}/>
            <p className='homeBrandModalTextBold'>{(!modalBrandPart && brandModal.id === 0 )?brandModal.title2:brandModal.title}</p>
            <p className='homeBrandModalTextBold'>Línea de Acción IB: <span className='homeBrandModalTextMini'>{(!modalBrandPart && brandModal.id === 0 )?brandModal.actionLine2:brandModal.actionLine}</span></p>
            <p className='homeBrandModalTextBold'>{(!modalBrandPart && brandModal.id === 0 )?brandModal.actionLine2:brandModal.place}</p>
            <li className='homeBrandModalText'>{(!modalBrandPart && brandModal.id === 0 )?brandModal.text2:brandModal.text}</li>
            {brandModal.id===0 &&
              <button className='homeBrandModalPageBtn' onClick={()=>setModalBrandPart(!modalBrandPart)}>{`${modalBrandPart?'1':'2'}/2 ▶`}</button>
            }
          </div>
        </div>
      }
    </main>
  )
}

export default Entidades