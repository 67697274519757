import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import LineasAccion from './components/lineasAccion/LineasAccion';
import PropuestasValor from './components/propuestasValor/PropuestasValor';
import Entidades from './components/entidades/Entidades';
import Equipo from './components/equipo/Equipo';
import Propuestas from './components/propuestas/Propuestas';
import TeoriaCambio from './components/teoriaCambio/TeoriaCambio';
import Biblioteca from './components/biblioteca/Biblioteca';

import './App.css';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home/>
    },
    {
      path: "/lineas-de-accion",
      element: <LineasAccion/>
    },
    {
      path: "/propuesta-de-valor",
      element: <PropuestasValor/>
    },
    {
      path: "/entidades",
      element: <Entidades/>
    },
    {
      path: "/equipo",
      element: <Equipo/>
    },
    {
      path: "/presentacion-propuestas",
      element: <Propuestas/>
    },
    {
      path: "/teoria-de-cambio",
      element: <TeoriaCambio/>
    },
    {
      path: "biblioteca",
      element: <Biblioteca/>
    }
  ])

  return (
    <div className="App">
      <Navbar/>
      <RouterProvider router={router}/>
      <Footer/>
    </div>
  );
}

export default App;
