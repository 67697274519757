import React, { useState } from 'react'

import './home.css'
import '../components.css'

import homeBrands from './homeBrands.json'

import mainBanner from '../../media/images/bannerQueHacemos.jpg'
import quienesSomosBub from '../../media/images/quienesSomosBub.jpg'
import queHacemos from '../../media/images/queHacemosBub.jpg'
import comoLoHacemos from '../../media/images/comoLoHacemosBub.jpg'
import lineaDeAccionBubA from '../../media/images/lineaDeAccionBubA.png'
import lineaDeAccionBubB from '../../media/images/lineaDeAccionBubB.jpg'

function Home() {
  const [openBrand, setOpenBrand] = useState(false)
  const [brandModal, setBrandModal] = useState({})
  const [modalBrandPart, setModalBrandPart] = useState(true)

  return (
    <main className='compMain'>
      <section className='homeBannerCont'>
        <img src={mainBanner} className='homeBanner' alt='Banner de Instituto Bicentenario'/>
        <div className='homeBannerGradient'/>
        <h2 className='homeBannerText'>Promovemos iniciativas que inciden en la consolidación democrática y el desarrollo institucional del país.</h2>
      </section>

      <section className='compMainSection'>

        <div className='compTitleCont'>
          <div className='compTitleLine'/>
          <h1 className='compTitle'>¿Quiénes somos?</h1>
          <div className='compTitleLine'/>
        </div>
        <div className='homeBubbleCont'>
          <img src={quienesSomosBub} className='homeBubbleImg' alt='¿Quiénes somos?'/>
          <p className='homeBubbleText'>El Instituto Bicentenario nace en 2022 a iniciativa del Banco de Crédito del Perú y del Grupo Crédito (Credicorp). Se trata de una entidad totalmente financiada por sus asociados y gobernada por un Consejo Directivo de hasta diez integrantes cuya mayoría es siempre independiente.</p>
        </div>

        <div className='compTitleCont'>
          <div className='compTitleLine'/>
          <h1 className='compTitle'>¿Qué hacemos?</h1>
          <div className='compTitleLine'/>
        </div>
        <div className='homeBubbleCont'>
          <p className='homeBubbleText'>Auspiciamos proyectos innovadores y escalables que fomenten el desarrollo institucional, seleccionados por un Consejo Directivo mayoritariamente independiente, que generen un impacto preferentemente fuera de Lima.</p>
          <img src={queHacemos} className='homeBubbleImg' alt='¿Quiénes somos?'/>
        </div>

        <div className='compTitleCont'>
          <div className='compTitleLine'/>
          <h1 className='compTitle'>¿Cómo lo hacemos?</h1>
          <div className='compTitleLine'/>
        </div>
        <div className='homeBubbleCont'>
          <img src={comoLoHacemos} className='homeBubbleImg' alt='¿Quiénes somos?'/>
          <p className='homeBubbleText'>Todas las iniciativas apoyadas por el Instituto Bicentenario pasarán por la revisión y aprobación del Consejo Directivo, la evaluación de Cumplimiento Corporativo y la identificación de indicadores clave que permitan medir su efectividad e impacto respecto de los objetivos y líneas de acción de la organización.</p>
        </div>

        <div className='compTitleCont'>
          <div className='compTitleLine'/>
          <h1 className='compTitle'>Líneas de acción</h1>
          <div className='compTitleLine'/>
        </div>
        <div className='compSubtitleCont'>
          <h3 className='compSubtitle'>Haz clic en los botones para conocer más</h3>
        </div>
        <div className='homeDoubleBubbleCont'>
          <div className='homeDoubleBubbleSection'>
            <img className='homeDoubleBubbleImg' src={lineaDeAccionBubA} alt="" />
            <a className='homeDoubleBubbleBtn' href='/lineas-de-accion' rel='noreferrer noopener'>Ciudadanía activa, responsable y vigilante</a>
          </div>
          <div className='homeDoubleBubbleSection'>
            <img className='homeDoubleBubbleImg' src={lineaDeAccionBubB} alt="" />
            <a className='homeDoubleBubbleBtn' href='/lineas-de-accion' rel='noreferrer noopener'>Instituciones descentralizadas y efectivas</a>
          </div>
        </div>

        <div className='compTitleCont'>
          <div className='compTitleLine'/>
          <h1 className='compTitle'>¿A quiénes hemos<span className='compTitleWeb'> apoyado?</span></h1>
          <div className='compTitleLine'/>
        </div>
        <div className='compTitleCont'>
          <h1 className='compTitle compTitleMob'>apoyado?</h1>
        </div>
        <div className='compSubtitleCont'>
          <h3 className='compSubtitle'>Haz clic en cada una de las organizaciones para saber más</h3>
        </div>
        <div className='homeBrandsCont'>
          {homeBrands.map((brand)=>{
            return(
              <button
                className='homeBrandBtn'
                onClick={()=>{
                  setBrandModal(brand)
                  setOpenBrand(true)
                }}
              >
                <img className='homeBrandImg' src={brand.logo} alt={`Logo de ${brand.name}`}/>
              </button>
            )
          })}
        </div>

      </section>

      {openBrand &&
        <div className='compModalCont'>
          <div className='homeBrandModal'>
            <button
              className='compModalCloseBtn'
              onClick={()=>{
                setOpenBrand(false)
                setBrandModal({})
              }}
            >
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.0359 7.67847L7.67871 23.0356" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.67871 7.67847L23.0359 23.0356" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <img className='homeBrandModalImg' src={brandModal.logoAlt} alt={`Logo de ${brandModal.name}`}/>
            <p className='homeBrandModalTextBold'>{(!modalBrandPart && brandModal.id === 0 )?brandModal.title2:brandModal.title}</p>
            <p className='homeBrandModalTextBold'>Línea de Acción IB: <span className='homeBrandModalTextMini'>{(!modalBrandPart && brandModal.id === 0 )?brandModal.actionLine2:brandModal.actionLine}</span></p>
            <p className='homeBrandModalTextBold'>{(!modalBrandPart && brandModal.id === 0 )?brandModal.actionLine2:brandModal.place}</p>
            <li className='homeBrandModalText'>{(!modalBrandPart && brandModal.id === 0 )?brandModal.text2:brandModal.text}</li>
            {brandModal.id===0 &&
              <button className='homeBrandModalPageBtn' onClick={()=>setModalBrandPart(!modalBrandPart)}>{`${modalBrandPart?'1':'2'}/2 ▶`}</button>
            }
          </div>
        </div>
      }

    </main>
  )
}

export default Home