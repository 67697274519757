import React from 'react'

import './teoriaCambio.css'

import teoriaCambio from '../../media/images/teoriaCambio.jpg'

function TeoriaCambio() {
  return (
    <main className='compMain'>
      <section className='compMainSection'>
        <div className='compTitleCont'>
          <div className='compTitleLine'/>
          <h1 className='compTitle'>Teoría de cambio</h1>
          <div className='compTitleLine'/>
        </div>
        <div className='compSubtitleCont'>
          <h3 className='compSubtitle'>Se espera que las intervenciones apoyadas por Instituto Bicentenario conduzcan a un mayor involucramiento ciudadano en la solución de problemas locales y a una toma de decisiones más eficaz por parte de los actores. Se espera que esto resulte en un aumento de la participación ciudadana en asuntos regionales, así como en una coordinación más eficiente entre los actores locales, lo que mejorará los procesos de rendición de cuentas, la gobernanza y la confianza en las instituciones, en beneficio de la resolución de asuntos de interés público.</h3>
        </div>
        <div className='teoriaCambioImgCont'>
          <img className='teoriaCambioImg' src={teoriaCambio} alt='Teoría de cambio'/>
        </div>
        <div className='compTitleBottom'>Teoría de cambio</div>
      </section>
    </main>
  )
}

export default TeoriaCambio