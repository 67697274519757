import React, { useState } from 'react'

import './equipo.css'

import teamUp from './teamUp.json'
import teamBottom from './teamBottom.json'

function Equipo() {
  const [openModal, setOpenModal] = useState(false)
  const [modalInfo, setModalInfo] = useState({})

  return (
    <main className='compMain'>
      <section className='compMainSection'>
        <div className='compTitleCont'>
          <div className='compTitleLine'/>
          <h1 className='compTitle'>Consejo Directivo</h1>
          <div className='compTitleLine'/>
        </div>

        <div className='compSubtitleCont teamSubtitleMob'>
          <h3 className='compSubtitle'>Desliza para ver todos nuestros directores</h3>
        </div>

        <div className='teamUpCont'>
        {teamUp.map((member)=>{
            return(
              <div className='teamUpMemberCont'>
                <img className='teamUpMemberImg' src={member.img} alt={`Foto de ${member.name}`}/>
                <div className='teamUpMemberDescCont'>
                  <p className='teamUpMemberTitle'>{member.name}</p>
                  <p className='teamUpMemberDesc'>{member.position}</p>
                  <button
                    className='teamUpMemberBtn'
                    onClick={()=>{
                      setModalInfo(member)
                      setOpenModal(true)
                    }}
                  >
                    Ver más
                  </button>
                </div>
              </div>
            )
          })}
        </div>

        <div className='compTitleCont'>
          <div className='compTitleLine'/>
          <h1 className='compTitle'>Equipo</h1>
          <div className='compTitleLine'/>
        </div>

        <div className='compSubtitleCont teamSubtitleMob'>
          <h3 className='compSubtitle'>Desliza para ver todos nuestros miembros de equipo</h3>
        </div>

        <div className='teamBottomCont'>
          {teamBottom.map((member)=>{
            return(
              <div className='teamBottomMemberCont'>
                <img className='teamBottomMemberImg'src={member.img} alt={`Foto de ${member.name}`}/>
                <div className='teamBottomMemberDescCont'>
                  <p className='teamBottomMemberTitle'>{member.name}</p>
                  <p className='teamBottomMemberDesc'>{member.position}</p>
                  <button
                    className='teamBottomMemberBtn'
                    onClick={()=>{
                      setModalInfo(member)
                      setOpenModal(true)
                    }}
                  >
                    Ver más
                  </button>
                </div>
              </div>
            )
          })}
        </div>

        <div className='compTitleBottom'>Equipo</div>
      </section>

      {openModal && 
        <div className='compModalCont'>
          <div className='teamModalCont'>
            <button
              className='compModalCloseBtn'
              onClick={()=>{
                setOpenModal(false)
                setModalInfo({})
              }}
            >
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.0359 7.67847L7.67871 23.0356" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.67871 7.67847L23.0359 23.0356" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <img className='teamModalImg' src={modalInfo.img} alt={`Foto de ${modalInfo.name}`}/>
            <div className='teamModalDescCont'>
              <p className='teamModalTitle'>{modalInfo.name}</p>
              <p className='teamModalDesc'>{modalInfo.position}</p>
              <p className='teamModalText'>{modalInfo.text}</p>
            </div>
          </div>
        </div>
      }
    </main>
  )
}

export default Equipo