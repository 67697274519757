import React from 'react'

import './propuestas.css'

function Propuestas() {
  return (
    <main className='compMain'>
      <section className='compMainSection'>
        <div className='compTitleCont'>
          <div className='compTitleLine'/>
            <h1 className='compTitle'>Guía para presentar<span className='compTitleWeb'> propuestas</span></h1>
          <div className='compTitleLine'/>
        </div>
        <div className='compTitleCont'>
          <h1 className='compTitle compTitleMob'>propuestas</h1>
        </div>
        <div className='compSubtitleCont'>
          <h3 className='compSubtitle'>¿Tienes un proyecto que se vincule a nuestras líneas de acción y que se pueda implementar en Piura, Loreto, Cajamarca, Arequipa, Junín o Cusco?</h3>
        </div>
        <div className='propuestasListCont'>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont' style={{opacity:0}}/>
            <p className='propuestasListTitle'>Te invitamos a remitir tu propuesta a <a className='propuestasListEmail' href='mailto:contacto@institutobicentenario.org' rel='noreferrer noopener'>contacto@institutobicentenario.org</a>, considerando los siguientes términos:</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>El documento con la propuesta deberá ser remitido a la Dirección Ejecutiva del Instituto Bicentenario a través del correo electrónico.</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>La longitud será de aproximadamente 1200-1800 palabras. Deberá incluir una síntesis que describa el objetivo, alcance y población impactada de implementar la iniciativa.</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>La propuesta deberá responder a al menos una de las líneas de acción definidas por el Instituto Bicentenario:</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont' style={{opacity:0}}>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>
              <li className='propuestasListItem'>Ciudadanía activa, vigilante y responsable.</li>
              <li className='propuestasListItem'>Instituciones descentralizadas efectivas.</li>
            </p>
          </div>

          <div className='propuestasListLine'>
            <p className='propuestasListText'>En ese sentido, se debe especificar dicha vinculación de modo explícito y conciso.</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>La propuesta deberá considerar al menos a una de las siguientes regiones para su implementación: Arequipa, Piura, Junín, Cajamarca, Loreto y Cusco.</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>El documento debe detallar la inversión requerida.</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>En caso aplique, también debe señalar el potencial de escalabilidad.</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>La propuesta debe identificar de forma clara sus indicadores clave de rendimiento (KPI). Asimismo, debe explica en un párrafo el impacto esperado en caso sea implementada.</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>La propuesta abarcará como máximo un año calendario. Al término de ese periodo, los proyectos están invitados a aplicar para financiar un segundo año de operación, lo que implicará un nuevo proceso de evaluación que incluirá los resultados obtenidos durante el primer año.</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>Se deberá especificar si se trata de la continuación de un proyecto desarrollado anteriormente. También si involucra alianzas entre organizaciones.</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>Solo se permitirá la participación de organizaciones registradas como personas jurídicas. Estas no podrán tener filiación partidaria ni intereses políticos. No es necesario sustentar un tiempo mínimo desde el inicio de operaciones para presentar una propuesta.</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>El equipo de IB será el responsable de verificar si la iniciativa cumple con los lineamientos definidos por el Consejo Directivo del Instituto Bicentenario. En ese proceso podrá reunirse y solicitar modificaciones y aclaraciones a las organizaciones respecto a sus propuestas. En caso sean seleccionadas, se solicitará una propuesta desarrollada para ser evaluada.</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>Adicionalmente, toda organización postulante deberá responder las siguientes preguntas (no incluidas en el conteo de las 1200-1800 palabras de la propuesta):</p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont' style={{opacity:'0'}}>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>
              <li className='propuestasListItem'>Si es que aplica, ¿de qué manera la propuesta plantea modelos de cambio en la ciudadanía?</li>
              <li className='propuestasListItem'>¿Cómo esta iniciativa podría remontar al deterioro de la institucionalidad en el Perú?</li>
              <li className='propuestasListItem'>¿Cómo esta propuesta contribuye a la consolidación democrática del país?</li>
            </p>
          </div>

          <div className='propuestasListLine'>
            <div className='propuestasListIconCont'>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.13 165.92">
                <path fill="#fff" strokeWidth="0px" d="M25.32,19.22c0,35.2,28.54,63.74,63.74,63.74V19.22H25.32Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M89.06,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fff" strokeWidth="0px" d="M152.81,146.7c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
                <path fill="#fc0000" strokeWidth="0px" d="M152.81,82.96c-35.2,0-63.74-28.54-63.74-63.74h63.74v63.74Z"/>
              </svg>
            </div>
            <p className='propuestasListText'>Finalmente, se deberá incluir una breve presentación de la organización postulante a los fondos (no incluidas en el conteo de las 1200-1800 palabras de la propuesta).</p>
          </div>

        </div>

        <div className='compTitleBottom'>¿Cómo postular?</div>
      </section>
    </main>
  )
}

export default Propuestas